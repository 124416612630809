import { getTimestampFromDateAndTimeInputs } from '../../../utilities/get-timestamp-from-date-and-time-inputs';

const REQUIRED_FIELD_MESSAGE =
  'Please fill every field in this section, if not leave it blank';

export const validateStartAndEndDateOnSubmit = ({
  startDate,
  startTime,
  endDate,
  endTime,
}) => {
  const values = [startDate, startTime, endDate, endTime];
  const hasSomeValues = values.some(Boolean);
  const hasAllValues = values.every(Boolean);

  if (hasSomeValues && !hasAllValues) {
    return REQUIRED_FIELD_MESSAGE;
  }
};

export const validateStartAndEndDateOnBlur = ({ watch, touchedFields }) => {
  const fieldNames = ['startDate', 'startTime', 'endDate', 'endTime'];

  const startDate = getTimestampFromDateAndTimeInputs(
    watch('startDate'),
    watch('startTime')
  );
  const endDate = getTimestampFromDateAndTimeInputs(
    watch('endDate'),
    watch('endTime')
  );

  // Run through all fields checking if touched it has a value
  for (const fieldName of fieldNames) {
    const value = watch(fieldName);
    const isTouched = touchedFields[fieldName] === true;
    if (isTouched && !value) {
      return REQUIRED_FIELD_MESSAGE;
    }
  }

  // Check that start date/time is after end date
  if (startDate && endDate) {
    if (startDate > endDate) {
      return 'Start date cannot be after the end date.';
    }
  }

  if (endDate) {
    if (endDate < Date.now()) {
      return 'End date cannot be in the past.';
    }
  }
};

export const getErroredAccordionIndexes = ({ errors }) => {
  const erroredIndexes = [];
  if (errors.title || errors.description || errors.links) {
    erroredIndexes.push(0);
  }
  if (errors.endTime) {
    erroredIndexes.push(1);
  }
  if (errors.userGroups) {
    erroredIndexes.push(2);
  }
  return erroredIndexes;
};
