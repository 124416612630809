import { DatePicker, TimePicker } from '@monash/portal-react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import c from './date-and-time.module.scss';
import InlineErrorMessage from '../inline-error-message/InlineErrorMessage';

const DateAndTime = ({
  required = false,
  errorMessage,
  label,
  names,
  onBlur,
  validateEndTime,
  disabled,
}) => {
  const { control } = useFormContext();

  return (
    <div className={c.inputGroup}>
      <label>{label}</label>

      <div className={c.dateAndTime}>
        <Controller
          control={control}
          name={names.date}
          render={({
            field: { onBlur: controllerOnBlur, onChange, value },
          }) => (
            <DatePicker
              dateFormat="dd/MM/yyyy"
              disablePast={true}
              selectedDay={value}
              setSelectedDay={onChange}
              onBlur={(event) => {
                controllerOnBlur(event);
                onBlur?.({ event, name: names.date });
              }}
              disabled={disabled}
            />
          )}
        />

        <Controller
          control={control}
          name={names.time}
          rules={{
            validate: validateEndTime,
            required,
          }}
          render={({
            field: { onBlur: controllerOnBlur, onChange, value },
          }) => (
            <TimePicker
              time={value}
              onChange={onChange}
              onBlur={(event) => {
                controllerOnBlur(event);
                onBlur?.({ event, name: names.time });
              }}
              disabled={disabled}
            />
          )}
        />
      </div>
      {errorMessage && <InlineErrorMessage message={errorMessage} />}
    </div>
  );
};

export default DateAndTime;
