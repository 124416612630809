import { InlineExternalLink } from '@monash/portal-react';

// notification types
export const NOTIFICATION_TYPE_IDS = {
  CRITICAL: 'critical',
  NON_CRITICAL: 'non-critical',
};

// history event types
export const NOTIFICATION_HISTORY_EVENT_TYPE_IDS = [
  'created',
  'edited',
  'published',
  'updated',
  'expired',
];

// publish statuses
export const NOTIFICATION_STATUS_IDS = ['scheduled', 'active', 'expired'];

// form

// notification
export const NOTIFICATION_EMPTY = {
  // data fields
  apps: [],
  studentUserGroups: [],
  staffUserGroups: [],
  title: '',
  description: '',
  startDate: null,
  endDate: null,
  links: [],
  type: '',
  // form fields
  startTime: '',
  endTime: '',
};

export const NOTIFICATION_TYPE_EMPTY_FORMS = {
  CRITICAL: { ...NOTIFICATION_EMPTY, authorisedBy: '' },
  NON_CRITICAL: NOTIFICATION_EMPTY,
};

// apps for publish
export const NOTIFICATION_APPS = {
  STUDENT: {
    id: 'student',
    label: 'Student portal apps',
    userGroupsKey: 'studentUserGroups',
  },
  STAFF: {
    id: 'staff',
    label: 'Staff portal',
    userGroupsKey: 'staffUserGroups',
  },
};

// link
export const NOTIFICATION_EMPTY_LINK = {
  label: '',
  url: '',
};

// success messages
export const NOTIFICATION_SUCCESS_MSGS = {
  SAVE: 'Notification has been saved',
  PUBLISH: 'Notification has been published',
  DELETE: 'Notification has been removed',
  END_NOW: 'Notification has been ended',
};

// server error messages (5xx)
export const NOTIFICATION_SERVER_ERROR_MSGS = {
  SAVE: "We're having trouble saving the notification right now - please try again.",
  PUBLISH:
    "We're having trouble publishing the notification right now - please try again.",
  DELETE:
    "We're having trouble deleting the notification right now - please try again.",
  END_NOW:
    "We're having trouble ending the notification right now - please try again.",
};

// client error messages (4xx)
export const NOTIFICATION_CLIENT_ERROR_MSGS = {
  SAVE: (
    <>
      We're having trouble saving the notification - please{' '}
      <InlineExternalLink
        text="log a bug"
        link="https://home.student.monash/feedback"
      />
    </>
  ),
  PUBLISH: (
    <>
      We're having trouble publishing the notification - please{' '}
      <InlineExternalLink
        text="log a bug"
        link="https://home.student.monash/feedback"
      />
    </>
  ),
  END_NOW: (
    <>
      We're having trouble ending the notification - please{' '}
      <InlineExternalLink
        text="log a bug"
        link="https://home.student.monash/feedback"
      />
    </>
  ),
};
