import React, { useState } from 'react';
import c from './expandable-card.module.scss';
import {
  Checkbox,
  Collapsible,
  Icon,
  IconButton,
  Tag,
} from '@monash/portal-react';
import { getTagText } from '../../utilities/get-tag-text';
import { TAG_COLORS } from '../../../constants/tag-colors';
import classNames from 'classnames';

const ExpandableCard = ({
  title,
  expandedContent,
  status,
  label,
  user,
  onClick,
  publishDate,
  hasCheckbox,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const tagText = getTagText({ status, publishDate });

  return (
    <div className={c.expandableCard}>
      <div className={c.header}>
        {hasCheckbox && (
          <Checkbox
            onChange={() => {
              setIsChecked((prevIsChecked) => {
                return !prevIsChecked;
              });
            }}
            on={isChecked}
          />
        )}
        <button
          className={c.headingGroupButton}
          onClick={onClick}
          type="button"
        >
          <span className={c.headingText}>
            <h3>{title}</h3>
            {label && <label>{label}</label>}
          </span>
          <span className={c.supplementaryInfo}>
            {user && <span>{user}</span>}
            <Tag color={TAG_COLORS[status]} text={tagText} />
          </span>
        </button>
        <IconButton
          icon={expanded ? Icon.ChevronUp : Icon.ChevronDown}
          aria-label={expanded ? 'Collapse' : 'Expand'}
          color="var(--card-text-color)"
          onClick={(e) => {
            e.stopPropagation();
            setExpanded((f) => !f);
          }}
        />
      </div>
      <Collapsible expanded={expanded}>
        <div
          className={classNames(c.description, {
            [c.hasCheckbox]: hasCheckbox,
          })}
        >
          {expandedContent}
        </div>
      </Collapsible>
    </div>
  );
};

export default ExpandableCard;
