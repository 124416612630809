export const UPDATES_EMPTY_VALUES = {
  title: '',
  description: '',
  links: [],
  startDate: null,
  startTime: '',
  endDate: null,
  endTime: '',
  publishDate: null,
  publishTime: null,
  userGroups: [],
};

export const SUBMIT_TYPES = {
  DRAFT: 'DRAFT',
  SAVE: 'SAVE',
  SCHEDULED: 'SCHEDULED',
  REMOVE_SCHEDULED: 'REMOVE_SCHEDULED',
  PUBLISH: 'PUBLISH',
};
