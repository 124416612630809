import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { APIContext } from '@monash/portal-frontend-common';
import {
  Button,
  DropdownWithValue,
  Icon,
  InlineExternalLink,
  SearchBox,
} from '@monash/portal-react';
import ExpandableCard from '../../common/expandable-card/ExpandableCard';
import { SlideOutContext } from '../../common/slide-out/SlideOutWrapper';
import UpdateForm from './update-form/UpdateForm';
import { byOrGroup, bySearchTerm, byStatus } from './utils';
import { STATUS_FILTERS } from './constants';
import c from './updates.module.scss';
import cs from '../../common/filter-dropdown/filter-dropdown.module.scss';
import {
  modifyCodesForCitizenship,
  removeCodeSuffix,
} from '../../common/user-groups-selector/utils';
import { formatDateRange } from '../../utilities/format-date';

const Updates = () => {
  const [searchTarget, setSearchTarget] = useState('');
  const [orGroupFilter, setOrGroupFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [updatesList, setUpdatesList] = useState([]);
  const [userGroups, setUserGroups] = useState([]);

  const { setSlide, setOpen } = useContext(SlideOutContext);
  const { getUserGroups, getUpdates } = useContext(APIContext);

  const userGroupDropdownList = [
    {
      text: 'Not selected',
      value: null,
    },
    ...modifyCodesForCitizenship(userGroups)?.map((item) => {
      return {
        text: `${removeCodeSuffix(item.code)} - ${item.name}`,
        value: item.code,
      };
    }),
  ];

  useEffect(() => {
    getUserGroups('updates')
      .then((response) => {
        setUserGroups(response?.student);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });

    getUpdates()
      .then((response) => {
        setUpdatesList(response);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  }, []);

  const openSlideForm = (update) => {
    setSlide({
      content: (
        <UpdateForm
          existingUpdate={update}
          userGroups={userGroups}
          setUpdatesList={setUpdatesList}
          getUpdates={getUpdates}
          key={JSON.stringify(update)}
        />
      ),
    });
    setOpen(true);
  };

  return (
    <div>
      <div className={c.header}>
        <div className={c.headingGroup}>
          <h1>Updates</h1>
        </div>
        <Button
          size="medium"
          icon={<Icon.Plus />}
          iconPosition="left"
          onClick={() => openSlideForm()}
        >
          Add update
        </Button>
      </div>

      <div className={c.searchWrapper}>
        <SearchBox searchString={searchTarget} onInput={setSearchTarget} />
      </div>

      <div className={c.filtersWrapper}>
        <DropdownWithValue
          id="orGroupFilter"
          placeholder="User group"
          ariaLabelledby="orGroup"
          onChange={setOrGroupFilter}
          value={orGroupFilter}
          data={userGroupDropdownList}
          className={cs.customDropdown}
        />
        <DropdownWithValue
          id="status"
          placeholder="Status"
          ariaLabelledby="status"
          onChange={setStatusFilter}
          value={statusFilter}
          data={STATUS_FILTERS}
          className={cs.customDropdown}
        />
      </div>

      <div className={c.cardsWrapper}>
        {updatesList
          .filter(bySearchTerm(searchTarget))
          .filter(byOrGroup(orGroupFilter))
          .filter(byStatus(statusFilter))
          .sort((a, b) => {
            const latestSaveA = a.history[a.history.length - 1]?.time;
            const latestSaveB = b.history[b.history.length - 1]?.time;
            if (latestSaveA > latestSaveB) {
              return -1;
            } else {
              return 1;
            }
          })
          .map((update) => {
            const { description, startDate, endDate, ...restUpdate } = update;
            const label = formatDateRange(startDate, endDate);
            return (
              <ExpandableCard
                onClick={() => {
                  openSlideForm(update);
                }}
                expandedContent={
                  <>
                    {description}

                    {update.links?.length && (
                      <div className={c.links}>
                        {update.links.map(({ label, url }) => {
                          const textLabel = label || url;
                          return (
                            <InlineExternalLink
                              link={url}
                              text={textLabel}
                              className={c.link}
                              key={label}
                            />
                          );
                        })}
                      </div>
                    )}
                  </>
                }
                label={label}
                {...restUpdate}
                key={update.id}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Updates;
