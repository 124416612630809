import {
  Icon,
  LoadingIndicator,
  SearchBox,
  Button,
  DropdownWithValue,
  Checkbox,
  fontStyles,
} from '@monash/portal-react';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { SlideOutContext } from '../../common/slide-out/SlideOutWrapper';
import c from './all-links-admin.module.scss';
import cs from '../../common/filter-dropdown/filter-dropdown.module.scss';
import LinkForm from '../link-form/LinkForm';
import { APIContext } from '@monash/portal-frontend-common';
import { USER_FILTERS, STATUS_FILTERS } from './constants';
import ExpandableCard from '../../common/expandable-card/ExpandableCard';
import classNames from 'classnames';
import { getFilterLists } from './utils';

const AllLinks = () => {
  const { setSlide, setOpen, slide } = useContext(SlideOutContext);
  const { search, updateLink, createLink, disableLink } =
    useContext(APIContext);

  const [searchData, setSearchData] = useState([]);
  const [searchTarget, setSearchTarget] = useState('');
  const [filteredData, setFilteredData] = useState([]); // TODO: this might not need to be a state, can be derived state instead
  const [loading, setLoading] = useState(false);

  const triggerSearch = useCallback(() => {
    search('', [], [], 'link').then((r) => {
      setSearchData(r.results || []);
      setLoading(false);
    });
    setLoading(true);
  }, [search]);

  useEffect(() => {
    triggerSearch();
  }, [triggerSearch]);

  // Filter search data
  useEffect(() => {
    if (searchTarget) {
      const searchTerms = searchTarget.replace(/[^a-zA-Z ]/g, ' ').split(' ');
      const filtered = searchData.filter((data) =>
        Object.keys(data.keywords).some((keyword) =>
          searchTerms.some((term) => keyword.includes(term))
        )
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(searchData);
    }
  }, [searchData, searchTarget]);

  const submitLink = (link) => {
    const linkPromise = link.id ? updateLink(link) : createLink(link);

    //  do whatever you need to do here
    linkPromise.then((r) => {
      openSlideForm(r);
      triggerSearch();
    });

    //  then let the children do something if needed
    return linkPromise;
  };

  const deleteLink = (link) => {
    disableLink(link.id, true).then((r) => {
      console.debug(link.id, 'deleted');
      // search() //maybe dont need this if delete locally
    });
    if (slide?.data?.id === link.id) {
      // is open in slideOut
      setOpen(false);
    }
    // remove from local list
    const i = searchData.findIndex((data) => data.entityId === link.id);
    if (i >= 0) {
      const deleting = [...searchData];
      deleting.splice(i, 1);
      setSearchData(deleting);
    }
  };

  const openSlideForm = (link) => {
    setSlide({
      content: (
        <LinkForm
          existing={link}
          deleteLink={() => deleteLink(link)}
          submit={submitLink}
          cancel={() => setOpen(false)}
          getKeywords={() => getKeywords(link?.id)}
        />
      ),
      data: link,
    });
    setOpen(true);
  };

  const getKeywords = (id) => {
    if (!id) return null;
    const keywords =
      searchData.filter((i) => i.entityId === id)[0]?.keywords || {};
    return { description: keywords };
  };

  // init filter values
  const [typeFilter, setTypeFilter] = useState('');
  const [appFilter, setAppFilter] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [categoriesFilter, setCategoriesFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const {
    type: typeFiltersList,
    app: appFiltersList,
    categories: categoriesFiltersList,
  } = getFilterLists(filteredData);

  return (
    <div className={c.container}>
      <div className={c.allLinks}>
        <div className={c.header}>
          <h1>Links</h1>
          <Button
            icon={<Icon.Plus />}
            iconPosition="left"
            variant="primary"
            size="medium"
            onClick={() => {
              openSlideForm(null);
            }}
            aria-label="add link"
          >
            Add link
          </Button>
        </div>
        <div>
          <SearchBox searchString={searchTarget} onInput={setSearchTarget} />
        </div>

        <div className={c.filtersWrapper}>
          <DropdownWithValue
            placeholder="Type"
            data={typeFiltersList}
            className={cs.customDropdown}
            value={typeFilter}
            onChange={setTypeFilter}
          />
          <DropdownWithValue
            placeholder="App"
            data={appFiltersList}
            className={cs.customDropdown}
            value={appFilter}
            onChange={setAppFilter}
          />
          <DropdownWithValue
            placeholder="User"
            data={USER_FILTERS}
            className={cs.customDropdown}
            value={userFilter}
            onChange={setUserFilter}
          />
          <DropdownWithValue
            placeholder="Categories"
            data={categoriesFiltersList}
            className={cs.customDropdown}
            value={categoriesFilter}
            onChange={setCategoriesFilter}
          />
          <DropdownWithValue
            placeholder="Status"
            data={STATUS_FILTERS}
            className={cs.customDropdown}
            value={statusFilter}
            onChange={setStatusFilter}
          />
        </div>

        <div className={c.secondaryHeader}>
          <div className={c.secondaryHeaderActions}>
            <Checkbox />
          </div>

          <p>{searchData.length} links</p>
        </div>

        {loading ? (
          <div className={c.loading}>
            <LoadingIndicator />
          </div>
        ) : (
          <div className={c.linksList}>
            {filteredData.map((indexed) => {
              const link = indexed.entityContent;
              const description = link.description;
              const categories = link.categories;

              return (
                <ExpandableCard
                  onClick={() => openSlideForm(link)}
                  title={link.name}
                  label={link.url}
                  status="Status" // TODO: this is a placeholder until status is included in links data
                  user="User" // TODO: this is a placeholder until user is included in links data
                  hasCheckbox
                  expandedContent={
                    <div className={c.collapsibleContent}>
                      <div className={c.section}>
                        <div className={classNames(fontStyles.label, c.label)}>
                          Description
                        </div>
                        <div>{description}</div>
                      </div>
                      {Array.isArray(categories) && categories.length > 0 && (
                        <div className={c.section}>
                          <div
                            className={classNames(fontStyles.label, c.label)}
                          >
                            Categories
                          </div>
                          {categories?.map((category) => {
                            return <div key={category}>{category}</div>;
                          })}
                        </div>
                      )}
                    </div>
                  }
                  key={link.name}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AllLinks;
